<template>
  <div>

    <!--       
    ################
    ################
    #### INLINE ####
    ################
    ################
    -->
    <b-form-group v-if="render=='inline'"
                  :label="control.tag"
                  :description="description"
                  :disabled="disabled">    
      <b-input-group size="sm">
        <b-form-input :list="'list' + control.tag" 
                      @click="find()" 
                      @keyup="find()" 
                      @blur.native="selectedItemByString()"
                      v-model="filter"                    
                      :state="isObjectSelected"
                      autocomplete="off">                    
        </b-form-input>      

        <b-input-group-append>
          <b-button variant="danger" 
                    v-b-tooltip.hover 
                    :title="'Limpiar ' + control.tag"
                    @click="clearObjectSelected()"
                    v-if="selectObject">
            <b-icon icon="trash"></b-icon>
          </b-button>          
        </b-input-group-append>        
      </b-input-group>
      
      <b-form-invalid-feedback v-html="control.alert" />      
      
      <datalist :id="'list' + control.tag">      
        <option v-for="item in data" :key="item.id">
          {{ getList(item) }}        
        </option>
      </datalist>
    </b-form-group>  
    
    <!--       
    ################
    ################
    #### SEARCH ####
    ################
    ################
    -->
    <b-form-group v-if="render=='search'"
                  :label="control.tag"
                  :description="description"
                  :disabled="disabled">
      <b-input-group size="sm">
        <b-form-input @keyup="openSearch()"
                      @click="openSearch()" 
                      v-model="filter"
                      :state="isObjectSelected"
                      autocomplete="off">
        </b-form-input>
        
        <b-input-group-append>
          <b-button variant="danger" 
                    v-b-tooltip.hover 
                    :title="'Limpiar ' + control.tag"
                    @click="clearObjectSelected()"
                    v-if="selectObject">
            <b-icon icon="trash"></b-icon>
          </b-button>          
        </b-input-group-append>

        <b-input-group-append>
          <b-button variant="secondary" 
                    v-b-tooltip.hover 
                    :title="'Buscar ' + control.tag"
                    @click="openSearch()">
            <b-icon icon="search"></b-icon>
          </b-button>          
        </b-input-group-append>
      </b-input-group>
      <b-form-invalid-feedback v-html="control.alert" />      
    </b-form-group>

    <b-modal v-model="modal.search.active"
               header-bg-variant="dark"
               header-text-variant="white"
               size="lg"
               no-close-on-esc
               no-close-on-backdrop
               hide-header-close>

        <div slot="modal-header">
          <Minimize />
          {{this.modal.search.title}}
        </div>

        <b-row>
          <!--all register -->
            <b-col lg="8" class="mb-2" v-if="control.enabledAllRegister">
              <b-form-checkbox v-model="allRegister" 
                                @input="getAllRegister()"
                                switch 
                                size="sm" 
                                class="pull-left">
                Todos los Registros
              </b-form-checkbox>                        
            </b-col>
          <!--fin-->

          <!--button add-->
            <b-col lg="4" class="mb-2" v-if="control.viewAdd">
              <b-button variant="outline-success pull-right" size="sm" @click="goViewAdd()" title="Nuevo registro">
                <b-icon icon="plus-lg"></b-icon>
                Agregar
              </b-button>            
            </b-col>
          <!--fin-->
          
          <!--text filter-->
            <b-col lg="12" class="mb-2" v-if="control.autoFilterEnabled">
              <b-form-input type="text"                             
                            :placeholder="'Buscar ' + control.tag" 
                            @keyup="find()" 
                            v-model="filter"
                            autofocus
                            size="sm"
                            v-if="!allRegister">
              </b-form-input>       
            </b-col>          
          <!--fin -->

          <!--text filter with button-->
            <b-col lg="12" class="mb-2" v-else id="popover-help-find">
              <b-input-group size="sm" v-if="!allRegister">
                <b-form-input type="text"                             
                              :placeholder="'Buscar ' + control.tag" 
                              v-on:keyup.enter.native="find('keypress.enter')"                               
                              v-model="filter"
                              title="ENTER para filtrar la búsqueda"
                              autofocus>
                </b-form-input>       
                <b-input-group-append>
                  <b-button variant="outline-info" @click="find('click')">
                    <b-icon icon="filter"></b-icon>
                    Filtrar
                  </b-button>       
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-popover target="popover-help-find" triggers="hover" placement="bottom" title="Ayuda" variant="info">
              <div v-html="control.alert"></div>
            </b-popover>
          <!--fin -->

          <b-col lg="12">            
            <b-table class="mb-0"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"                    
                    :items="table.search.items"
                    :fields="table.search.fields"
                    :current-page="table.search.currentPage"
                    :per-page="table.search.perPage"                    
                    v-if="table.search.items.length">
            
              <template v-slot:cell(name)="row">
                <div v-if="control.viewEdit != ''">
                  <b-link href="javascript:void(0);" 
                          :title="'CLICK PARA EDITAR: ' + row.item.name" 
                          @click="goViewEdit(row.item)">                    
                    {{row.item.name}}
                  </b-link>
                </div>
                <div v-else>
                  {{row.item.name}}
                </div>
              </template>

              <template v-slot:cell(f_action)="row">
                <b-button variant="outline-dark" 
                          size="sm" 
                          @click="selectedItem(row.item)"
                          class="pull-right"
                          title="Seleccionar">
                  <b-icon icon="chevron-double-right"></b-icon>
                </b-button>
              </template>

            </b-table>
            <b-alert v-else variant="warning" show>
              No se encontraron registros
            </b-alert>
          </b-col>
          <b-col lg="12">
            <nav>
              <b-pagination class="pull-right mt-2"
                            size="sm"
                            pills
                            :total-rows="getRowCount(table.search.items)"
                            :per-page="table.search.perPage"
                            v-model="table.search.currentPage"/>
            </nav>
          </b-col>
        </b-row>
        <div slot="modal-footer">
          <b-button variant="outline-secondary" 
                    class="pull-right" 
                    @click="closeSearch()">
            Cerrar
          </b-button>          
        </div>        
    </b-modal>
  </div>
</template>
<script>  
  import serviceAPI from './services'
  import ErrorToken from '@/handler/errorToken'
  import Helper from '@/handler/helper'
  import Param from '@/config/parameters'
  import Minimize from '@/components/inc/modal/minimize'

  export default {
    components: {
      Minimize,
    },    
    props: {
      render: {
        type: String,
        default: 'inline'
      },
      type:{
        type: String,
        required: true
      },
      valueID: {
        type: Number,
        default: 0,
      },  
      valueSTR: {
        type: String,
        default: '',
      },        
      referenceSTR: {
        type: String,
        default: '',
      },      
      tag: {
        type: String,
        default: ''
      },
      where: {
        type: Array,
        default: function () { return [] }       
      },
      orWhere: {
        type: Array,
        default: function () { return [] }       
      },      
      description: {
        type: String,
        default: ''
      },
      showAllRegister: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,        
      },
      display: {
        type: String,
        default: 'name'
      }
    },
    data: () => {
      return {     
        parameters: {
          codeCustomer: Helper.hasParametersAccess(Param.P2),
          codeSuppliers: Helper.hasParametersAccess(Param.P3)
        },                        
        filter: '',           
        selectID: 0,
        data: [],                    
        selectObject: null,
        control: {
          tag:'',
          alert: '',
          list: [],
          fieldList: [],
          urlShowAPI: '',
          urlFilterAPI: '',   
          searchResultLength: 0,
          autoFilterEnabled: true,
          enabledAllRegister: true,
          viewAdd: '',          
          viewEdit: '',          
          viewEditParamID: '',
        },
        modal: {
          search: {
            active: false,
            title: ''
          },
        },
        table : {
          search: {
            items: [],
            fields: [],
            currentPage: 1,
            perPage: 10,          
          }
        }, 
        allRegister: false,            
      }      
    },    
    computed: {
      isObjectSelected() {           
        return this.selectObject == null ? false : true
      }           
    },    
    mounted() {      
      this.config()
      
      if(this.valueID) {  
        this.selectID = this.valueID      
        this.selected()
      }

      if(this.valueSTR) {          
        this.selectID = this.valueSTR
        this.selected()
      }      

      if(this.tag) {
        this.control.tag = this.tag
      }

      if(this.showAllRegister) {
        this.allRegister = true
      }
    },
    methods: {
      // configuracion general
      config() {
        switch(this.type) {
          case "staff":
            this.control.tag = "Staff"
            this.control.alert = "Seleccionar un STAFF. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']    
            this.control.fieldList = ['Nombre']        
            this.control.urlShowAPI = "staff/find/control/controlShow"
            this.control.urlFilterAPI = "staff/find/control/controlByFilter"           
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break

          case "staff-subscription-push":
            this.control.tag = "Staff Subscriptos"
            this.control.alert = "Seleccionar un STAFF. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']    
            this.control.fieldList = ['Nombre']        
            this.control.urlShowAPI = "staff/find/control/controlShowSuscriptionPush"
            this.control.urlFilterAPI = "staff/find/control/controlByFilterSuscriptionPush"           
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break

          case "customers":
            this.control.tag = "Clientes"
            this.control.alert = "Seleccionar un CLIENTE. <b>Buscar por [ID | Código | Nombre]</b>"
            this.control.list = ['code','name']
            this.control.fieldList = ['Código', 'Nombre']                    
            this.control.urlShowAPI = "customers/find/control/controlShow"
            this.control.urlFilterAPI = "customers/find/control/controlByFilter" 
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = 'CustomerListStaff'
            this.control.viewEdit = 'CustomerViewStaff'
            this.control.viewEditParamID = 'customersID'
            break

          case "customers-subscription-push":
            this.control.tag = "Clientes Subscriptos"
            this.control.alert = "Seleccionar un CLIENTE. <b>Buscar por [ID | Código | Nombre]</b>"
            this.control.list = ['code','name']
            this.control.fieldList = ['Código', 'Nombre']                    
            this.control.urlShowAPI = "customers/find/control/controlShowSuscriptionPush"
            this.control.urlFilterAPI = "customers/find/control/controlByFilterSuscriptionPush" 
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break

          case "suppliers":
            this.control.tag = "Proveedores"
            this.control.alert = "Seleccionar un PROVEEDOR. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['code','name']
            this.control.fieldList = ['Código', 'Nombre']                    
            this.control.urlShowAPI = "suppliers/find/control/controlShow"
            this.control.urlFilterAPI = "suppliers/find/control/controlByFilter"            
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = 'SuppliersListStaff'
            this.control.viewEdit = 'SuppliersViewStaff'
            this.control.viewEditParamID = 'suppliersID'
            break

          case "suppliers-subscription-push":
            this.control.tag = "Proveedores Subscriptos"
            this.control.alert = "Seleccionar un PROVEEDOR. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['code','name']
            this.control.fieldList = ['Código', 'Nombre']                    
            this.control.urlShowAPI = "suppliers/find/control/controlShowSuscriptionPush"
            this.control.urlFilterAPI = "suppliers/find/control/controlByFilterSuscriptionPush"            
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break

          case "tanks":
            this.control.tag = "Tanques"
            this.control.alert = "Seleccionar tanque. <b>Buscar por [ID | Código | Nombre]</b>"
            this.control.list = ['code', 'name']
            this.control.fieldList = ['Código', 'Nombre']                    
            this.control.urlShowAPI = "sensors/tanks/find/control/controlShow"
            this.control.urlFilterAPI = "sensors/tanks/find/control/controlByFilter"            
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break

          case "employee":
            this.control.tag = "Empleados"
            this.control.alert = "Seleccionar empleados. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "employee/employee/find/control/controlShow"
            this.control.urlFilterAPI = "employee/employee/find/control/controlByFilter"              
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "employeeCategory":
            this.control.tag = "Categorías"
            this.control.alert = "Seleccionar categoría. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "employee/category/find/control/controlShow"
            this.control.urlFilterAPI = "employee/category/find/control/controlByFilter"              
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break  

          case "project":
            this.control.tag = "Proyectos"
            this.control.alert = "Seleccionar proyecto. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name', 'customer.name']
            this.control.fieldList = ['Nombre', 'Cliente']
            this.control.urlShowAPI = "projects/projects/find/control/controlShow"
            this.control.urlFilterAPI = "projects/projects/find/control/controlByFilter"                
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break      
                              
          case "activity":
            this.control.tag = "Actividades"
            this.control.alert = "Seleccionar actividad. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "projects/activity/find/control/controlShow"
            this.control.urlFilterAPI = "projects/activity/find/control/controlByFilter"                
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break       
            
          case "products":
            this.control.tag = "Productos"
            this.control.alert = "Seleccionar productos. <b>Buscar por [ID | Código | Nombre | Código de Barra]</b>"
            this.control.list = ['code','name']
            this.control.fieldList = ['Código','Nombre']
            this.control.urlShowAPI = "products/find/control/controlShow"
            this.control.urlFilterAPI = "products/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 2
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = false 
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break

          case "productsLine":
            this.control.tag = "Lineas"
            this.control.alert = "Seleccionar lineas. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "products-line/find/control/controlShow"
            this.control.urlFilterAPI = "products-line/find/control/controlByFilter"                
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break                   
            
          case "productsCategory":
            this.control.tag = "Categorias"
            this.control.alert = "Seleccionar categorías. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "products-category/find/control/controlShow"
            this.control.urlFilterAPI = "products-category/find/control/controlByFilter"                  
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "productsSubCategory":
            this.control.tag = "Categorías"
            this.control.alert = "Seleccionar sub-categorías. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['category.name', 'name']
            this.control.fieldList = ['Categoría', 'Subcategoría']
            this.control.urlShowAPI = "products-subcategory/find/control/controlShow"
            this.control.urlFilterAPI = "products-subcategory/find/control/controlByFilter"              
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "productsSubCategoryParent":
            this.control.tag = "Categorías"
            this.control.alert = "Seleccionar sub-categorías. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['category.name', 'name']
            this.control.fieldList = ['Categoría', 'Subcategoría']
            this.control.urlShowAPI = "products-subcategory/find/control/controlShowParent"
            this.control.urlFilterAPI = "products-subcategory/find/control/controlByFilterParent"
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "productsSubLine":
            this.control.tag = "Lineas"
            this.control.alert = "Seleccionar sub-lineas. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['line.name','name']
            this.control.fieldList = ['Linea', 'Sublinea']
            this.control.urlShowAPI = "products-subline/find/control/controlShow"
            this.control.urlFilterAPI = "products-subline/find/control/controlByFilter"                
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "productsColors":
            this.control.tag = "Colores"
            this.control.alert = "Seleccionar colores. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "products-colors/find/control/controlShow"
            this.control.urlFilterAPI = "products-colors/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "productsWaist":
            this.control.tag = "Talles"
            this.control.alert = "Seleccionar talles. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "products-waist/find/control/controlShow"
            this.control.urlFilterAPI = "products-waist/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "sellers":
            this.control.tag = "Vendedores"
            this.control.alert = "Seleccionar vendedor. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "sellers/find/control/controlShow"
            this.control.urlFilterAPI = "sellers/find/control/controlByFilter"   
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "sellersZone":
            this.control.tag = "Zonas"
            this.control.alert = "Seleccionar zona. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "sellers-zone/find/control/controlShow"
            this.control.urlFilterAPI = "sellers-zone/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "currency":
            this.control.tag = "Moneda"
            this.control.alert = "Seleccionar moneda. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "price-currency/find/control/controlShow"
            this.control.urlFilterAPI = "price-currency/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "contentsBlock":
            this.control.tag = "Bloque"
            this.control.alert = "Seleccionar bloque. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "contents-block/find/control/controlShow"
            this.control.urlFilterAPI = "contents-block/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "courses":
            this.control.tag = "Cursos"
            this.control.alert = "Seleccionar cursos. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "courses/find/control/controlShow"
            this.control.urlFilterAPI = "courses/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break

          case "coursesCategory":
            this.control.tag = "Categorias"
            this.control.alert = "Seleccionar categorías. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "courses-category/find/control/controlShow"
            this.control.urlFilterAPI = "courses-category/find/control/controlByFilter"                  
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "coursesSubCategory":
            this.control.tag = "Categorías"
            this.control.alert = "Seleccionar sub-categorías. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['category.name', 'name']
            this.control.fieldList = ['Categoría', 'Subcategoría']
            this.control.urlShowAPI = "courses-subcategory/find/control/controlShow"
            this.control.urlFilterAPI = "courses-subcategory/find/control/controlByFilter"              
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "servicesCategory":
            this.control.tag = "Categorias"
            this.control.alert = "Seleccionar categorías. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "services-category/find/control/controlShow"
            this.control.urlFilterAPI = "services-category/find/control/controlByFilter"                  
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break             

          case "services":
            this.control.tag = "Servicios"
            this.control.alert = "Seleccionar servicios. <b>Buscar por [ID | Categoría | Nombre]</b>"
            this.control.list = ['category.name', 'name']
            this.control.fieldList = ['Categoría', 'Nombre']
            this.control.urlShowAPI = "services/find/control/controlShow"
            this.control.urlFilterAPI = "services/find/control/controlByFilter"                  
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "servicesCustomers":
            this.control.tag = "Servicios de Cliente"
            this.control.alert = "Seleccionar servicios. <b>Buscar por [Referencia | Servicio]</b>"
            this.control.list = ['service_customer.name', 'service.name']
            this.control.fieldList = ['Referencia','Servicio']
            this.control.urlShowAPI = "services-customers-details/find/control/controlShow"
            this.control.urlFilterAPI = "services-customers-details/find/control/controlByFilter"
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "reports":
            this.control.tag = "Reportes"
            this.control.alert = "Seleccionar reportes. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "reports/find/control/controlShow"
            this.control.urlFilterAPI = "reports/find/control/controlByFilter"                  
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "priceList":
            this.control.tag = "Lista de Precios"
            this.control.alert = "Seleccionar lista de precios. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "price-list/find/control/controlShow"
            this.control.urlFilterAPI = "price-list/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "erpPointsSales":
            this.allRegister = true
            this.control.tag = "Punto de Venta"
            this.control.alert = "Seleccionar puntos de ventas. <b>Buscar por [ID | Punto de Venta | Nombre]</b>"
            this.control.list = ['business.name', 'point_sale', 'name']
            this.control.fieldList = ['Empresa', 'Punto de Venta', 'Nombre']
            this.control.urlShowAPI = "sales-points-sales/find/control/controlShow"
            this.control.urlFilterAPI = "sales-points-sales/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false            
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "erpMethodsPayment":
            this.control.tag = "Métodos de Pago"
            this.control.alert = "Seleccionar métodos de pago. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "sales-methods-payment/find/control/controlShow"
            this.control.urlFilterAPI = "sales-methods-payment/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "erpFiscalConditions":
            this.control.tag = "Condiciones Fiscales"
            this.control.alert = "Seleccionar condición fiscal. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "sales-fiscal-conditions/find/control/controlShow"
            this.control.urlFilterAPI = "sales-fiscal-conditions/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break             

          case "erpIvaConditions":
            this.control.tag = "Condiciones de IVA"
            this.control.alert = "Seleccionar condición de IVA. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "sales-iva-conditions/find/control/controlShow"
            this.control.urlFilterAPI = "sales-iva-conditions/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break  

          case "erpTypeDocuments":
            this.control.tag = "Tipos de Documentos"
            this.control.alert = "Seleccionar tipo de documento. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "sales-types-documents/find/control/controlShow"
            this.control.urlFilterAPI = "sales-types-documents/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break  

          case "accountingAccounts":
            this.control.tag = "Cuentas Contables"
            this.control.alert = "Seleccionar la cuenta contable. <b>Buscar por [Código | Nombre]</b>"
            this.control.list = ['code', 'name', 'parent.name', 'type']
            this.control.fieldList = ['Código', 'Nombre', 'Grupo', 'Tipo']
            this.control.urlShowAPI = "accounting-accounts/find/control/controlShow"
            this.control.urlFilterAPI = "accounting-accounts/find/control/controlByFilter"
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break  

          case "accountingAccountsNotImputation":
            this.control.tag = "Cuentas Contables"
            this.control.alert = "Seleccionar la cuenta contable. <b>Buscar por [Código | Nombre]</b>"
            this.control.list = ['code', 'name', 'parent.name', 'type']
            this.control.fieldList = ['Código', 'Nombre', 'Grupo', 'Tipo']
            this.control.urlShowAPI = "accounting-accounts/find/control/controlShowNotImputation"
            this.control.urlFilterAPI = "accounting-accounts/find/control/controlByFilterNotImputation"
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break  

          case "accountingAccountsImputation":
            this.control.tag = "Cuentas Contables"
            this.control.alert = "Seleccionar la cuenta contable. <b>Buscar por [Código | Nombre]</b>"
            this.control.list = ['code', 'name', 'parent.name', 'type']
            this.control.fieldList = ['Código', 'Nombre', 'Grupo', 'Tipo']
            this.control.urlShowAPI = "accounting-accounts/find/control/controlShowImputation"
            this.control.urlFilterAPI = "accounting-accounts/find/control/controlByFilterImputation"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break     
            
          case "whatsappMarketingList":
            this.control.tag = "Lista de Contactos"
            this.control.alert = "Seleccionar lista de contactos. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "whatsapp-marketing-list/find/control/controlShow"
            this.control.urlFilterAPI = "whatsapp-marketing-list/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 
            
          case "cash":
            this.control.tag = "Caja"
            this.control.alert = "Seleccionar caja. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "finances-cash/find/control/controlShow"
            this.control.urlFilterAPI = "finances-cash/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break 

          case "concepts":
            this.control.tag = "Conceptos"
            this.control.alert = "Seleccionar conceptos. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "sales-concepts/find/control/controlShow"
            this.control.urlFilterAPI = "sales-concepts/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break
            
          case "productsCompound":
            this.control.tag = "Productos Compuestos"
            this.control.alert = "Seleccionar productos compuestos. <b>Buscar por [ID | Código | Nombre]</b>"
            this.control.list = ['code','name']
            this.control.fieldList = ['Código','Nombre']
            this.control.urlShowAPI = "products-compound/find/control/controlShow"
            this.control.urlFilterAPI = "products-compound/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break             

          case "unitMeasurement":
            this.control.tag = "Unidades de Medidas"
            this.control.alert = "Seleccionar unidades de medidas. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['reference','name']
            this.control.fieldList = ['Referencia','Nombre']
            this.control.urlShowAPI = "products-unit-measurement/find/control/controlShow"
            this.control.urlFilterAPI = "products-unit-measurement/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break       
            
          case "deposits":
            this.allRegister = this.showAllRegister
            this.control.tag = "Depósitos"
            this.control.alert = "Seleccionar depósitos. <b>Buscar por [Referencia | Nombre]</b>"
            this.control.list = ['reference', 'name']
            this.control.fieldList = ['Depósito', 'Nombre']
            this.control.urlShowAPI = "deposits/find/control/controlShow"
            this.control.urlFilterAPI = "deposits/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false            
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break                   

          case "deposits-zone":
            this.control.tag = "Zona de Depósitos"
            this.control.alert = "Seleccionar zonas de depósitos. <b>Buscar por [Referencia | Nombre]</b>"
            this.control.list = ['deposit.reference', 'reference', 'name']
            this.control.fieldList = ['Depósito','Zona', 'Nombre']
            this.control.urlShowAPI = "deposits-zone/find/control/controlShow"
            this.control.urlFilterAPI = "deposits-zone/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break                   

          case "deposits-location":
            this.allRegister = this.showAllRegister
            this.control.tag = "Ubicación en Zonas de Depósitos"
            this.control.alert = "Dos formas para buscar una ubicación:<br><br><b>Genérica:</b> Seleccionar una ubicación de depósitos. <b>Buscar por [Referencia | Nombre]</b> <br><br> <b>Avanzado:</b> D:<i>ref</i>,Z:<i>ref</i>,U:<i>ref</i> (Puede omitir parametros pudiendo filtrar solo por depósito, zona y/o ubicación)"
            this.control.list = ['zone.deposit.reference', 'zone.reference', 'reference', 'name']
            this.control.fieldList = ['Depósito','Zona', 'Ubicación', 'Nombre']
            this.control.urlShowAPI = "deposits-locations/find/control/controlShow"
            this.control.urlFilterAPI = "deposits-locations/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false            
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break    

          case "instances-server":
            this.allRegister = this.showAllRegister
            this.control.tag = "Servidores (VPS)"
            this.control.alert = "Seleccionar un servidor. <b>Buscar por [Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "instances-server/find/control/controlShow"
            this.control.urlFilterAPI = "instances-server/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false            
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break    

          case "instances-categories":
            this.allRegister = this.showAllRegister
            this.control.tag = "Agrupación de Instancias"
            this.control.alert = "Seleccionar una agrupación. <b>Buscar por [Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "instances-categories/find/control/controlShow"
            this.control.urlFilterAPI = "instances-categories/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false            
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break                

          case "erpMethodsAcopio":
            this.control.tag = "Métodos de Acopio"
            this.control.alert = "Seleccionar métodos de acopio. <b>Buscar por [ID | Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "sales-methods-acopio/find/control/controlShow"
            this.control.urlFilterAPI = "sales-methods-acopio/find/control/controlByFilter"                    
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break     
            
          case "orders-reason":
            this.allRegister = this.showAllRegister
            this.control.tag = "Motivos"
            this.control.alert = "Seleccionar un motivo. <b>Buscar por [Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "orders-reason/find/control/controlShow"
            this.control.urlFilterAPI = "orders-reason/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false            
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break     
            
          case "my-business":
            this.allRegister = this.showAllRegister
            this.control.tag = "Empresas"
            this.control.alert = "Seleccionar una empresa. <b>Buscar por [Nombre]</b>"
            this.control.list = ['name']
            this.control.fieldList = ['Nombre']
            this.control.urlShowAPI = "accounting-business/find/control/controlShow"
            this.control.urlFilterAPI = "accounting-business/find/control/controlByFilter"                                                     
            this.control.searchResultLength = 0
            this.control.autoFilterEnabled = false            
            this.control.enabledAllRegister = true
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break      
            
          case "costsSuppliersProductsSupport":
            this.control.tag = "Productos Externos"
            this.control.alert = "Seleccionar productos externos. <b>Buscar por [Referencia | Proveedor | Producto]</b>"
            this.control.list = ['reference', 'suppliers', 'description', 'price']
            this.control.fieldList = ['Referencia Externa', 'Proveedor', 'Producto', 'Precio']
            this.control.urlShowAPI = "costs/suppliers-products-update-external-support/find/control/controlShow"
            this.control.urlFilterAPI = "costs/suppliers-products-update-external-support/find/control/controlByFilter"                  
            this.control.searchResultLength = 3
            this.control.autoFilterEnabled = false
            this.control.enabledAllRegister = false
            this.control.viewAdd = ''
            this.control.viewEdit = ''
            this.control.viewEditParamID = ''
            break                 
        }
      },

      // busqueda en la base de datos 
      find(event='keyup') {                     
        // no es autofiltro y no se 
        if(!this.control.autoFilterEnabled && event=='keyup' && this.render=='search') {
          return false
        }
        
        if(this.render=='search') {
          this.filter = this.filter.trim()
        }        
        
        if(this.filter.length>this.control.searchResultLength) {           
          var result = serviceAPI.filter(this.filter, this.where, this.control, this.orWhere)      

          result.then((response) => {
            var data = response.data
            this.data = data
            this.table.search.items = data
          })
          .catch(error => {          
            this.$awn.alert(ErrorToken.valid(error))                             
          });        
        } else {
          if(this.render=='search') {
            this.$awn.info("Se necesita un mínimo de " + (parseFloat(this.control.searchResultLength) + 1) + ' caracteres para efectuar el filtro');
            this.table.search.items = []
          }
        }
      },
      getAllRegister() {   
        if(this.allRegister) {     
          var result = serviceAPI.filter(null, this.where, this.control, this.orWhere)      

          result.then((response) => {
            var data = response.data
            this.data = data
            this.table.search.items = data
          })
          .catch(error => {          
            this.$awn.alert(ErrorToken.valid(error))                             
          });                
        } else {
          this.table.search.items = []
        }
      },

      // seleccionar el item 
      selectedItemByString() {
        if(this.filter.includes('-')) {
          if(this.referenceSTR) {            
            this.selectID = this.filter.split('-')[1].trim()            
          } else {
            this.selectID = this.filter.split('-')[0].trim()            
          }          
          this.selected()
        } else {
          this.selected()
        }
      },      
      selectedItem(item) {            
        if(this.referenceSTR) {
          this.selectID = item[this.referenceSTR]
        } else {
          this.selectID = item.id
        }
        this.selected()
        this.modal.search.active = false
      }, 
      selected(){        
        var id = 0

        if(this.selectID) {
          id = this.selectID
        }          
        
        if(!id){          
          this.filter = '' 
          this.selectObject = null    
          this.$emit('select-object', null)                         
          return false
        }

        var result = serviceAPI.selected(id, this.where, this.control, this.orWhere)      

        result.then((response) => {          
          var data = response.data 
          
          if(Object.keys(data).length){                   
            this.filter = data[this.display]            
            
            this.selectObject = data   
            this.$emit('select-object', data)       
          } else {
            this.clearObjectSelected()          
            return false
          }        
        })   
        .catch(error => {
          this.clearObjectSelected()
          this.$awn.alert(ErrorToken.valid(error))                   
          return false
        });             
      }, 

      // inline - configuracion de lista desplegable 
      getList(item) {                     
        var value = item.id + ' - '
        this.control.list.forEach(element => {                              
          if(element.split('.').length==1){
            value = value + item[element] + " - "
          } else {
            var valSecondary = item
            element.split('.').forEach(element1 => {
              valSecondary = valSecondary[element1]              
            });            
            value = value + valSecondary + " - "
          }
        })
        return value.slice(0, -3)
      },

      // search - configuracion modal y table
      getRowCount (items) {
        return items.length
      },       
      openSearch(){        
        this.modal.search.active = true
        this.modal.search.title = 'Buscador de ' + this.control.tag            
        this.setFieldTable()       
        this.find()         

        if(this.allRegister) {     
          this.getAllRegister()
        }              
      },
      setFieldTable() {
        var showID = true
        if(this.type == 'customers') { 
          if(this.parameters.codeCustomer) {
            showID = false
          }          
        }
        if(this.type == 'suppliers') { 
          if(this.parameters.codeSuppliers) {
            showID = false
          }          
        }

        this.table.search.fields = []
        if(showID) {
          this.table.search.fields.push({key: 'id', label:'ID', class:"align-middle"})             
        }        
        this.control.fieldList.forEach((element, key) => {
          this.table.search.fields.push({key: this.control.list[key], label: element, class:"align-middle"})            
        });        
        this.table.search.fields.push({key: 'f_action', label:'', class:"align-middle"})             
      },      
      closeSearch(){                
        if(this.selectObject) {
          if(this.referenceSTR) {
            this.filter = this.selectObject[this.referenceSTR]            
          } else {
            this.filter = this.selectObject["name"]
          }                    
        }        
        this.modal.search.active = false
      },

      // vaciar objeto seleccionado
      clearObjectSelected() {
        this.filter = ''
        this.selectObject = null    
        this.$emit('select-object', null) 
      },

      // abm objeto
      goViewAdd() {
        const route = this.$router.resolve({ name: this.control.viewAdd, query: {add: 'open'} });
        window.open(route.href, '_blank');        
      },
      goViewEdit(item) {        
        const paramName = this.control.viewEditParamID
        const paramsObj = {};
        paramsObj[paramName] = item.id;

        const route = this.$router.resolve({ name: this.control.viewEdit, params: paramsObj });
        window.open(route.href, '_blank');        
      }
    }
  }
</script>